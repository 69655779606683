/* eslint-env jquery */

'use strict';

jQuery( function( $ ) {
    $('.slider-media').owlCarousel( {
		loop     		: true,
		margin   		: 0,
		items    		: 1,
		dots   	 		: false,
		autoplay 		: true,
		autoplayTimeout : 7000,
		autoplaySpeed   : 2000,
		navSpeed		: 2000,
		responsive      : {
			0: {
				nav   : false,
			},
			992: {
				nav    : true,
			},
		},
	} );

	$( document ).on( 'click', '#showMobileMenu', function( event ) {
		event.preventDefault();
		$( '#mobileMenu' ).animate( { "right":"0" }, "slow").removeClass( 'visible' );
	} );

	$( document ).on( 'click', '#hideMobileMenu', function( event ) {
		event.preventDefault();
		$( '#mobileMenu' ).animate( { "right":"-350px" }, "slow").removeClass( 'visible' );
	} );

	$( document ).on( 'click', '.menu-toggler', function( event ) {
		event.preventDefault();

	    const element = $( this );

	    if ( element.hasClass( 'show' ) ) {
	        element.removeClass( 'show' );
	        element.next().slideUp( 350 );
	    } else {
			if ( $( '.show' ).length ) {
				$('.show').next().slideUp('350');
				$('.show').removeClass( 'show' );
			}
			element.addClass( 'show' );
			element.next().slideToggle( 350 );
		}
	} );

} );
